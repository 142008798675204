import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconComponent, InteractaIcon } from '@interacta-shared/ui-icons';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'button[interacta-button-menu-vertical]',
    templateUrl: './button-menu-vertical.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgClass, IconComponent],
})
export class ButtonMenuVerticalComponent {
    @Input() icon!: InteractaIcon;
    @Input() label!: string;
    @Input() selected = false;
    @Input() fixedWidth = false;
}
