<div
    class="text-text-low-contrast hover:bg-surface-100 active:bg-surface-300 rounded p-4 transition-colors"
    [ngClass]="{
        'min-w-64': !fixedWidth,
        'w-64': fixedWidth,
    }"
>
    <div
        class="flex flex-col items-center transition-colors duration-75 ease-linear"
        [ngClass]="{ 'text-text-primary': selected }"
    >
        <interacta-icon class="h-36 w-36" [icon]="icon"></interacta-icon>

        <span class="typo-xs-bold mt-4">{{ label }}</span>
    </div>
</div>
